import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from '../shared/services/local-storage/local-storage.service';
import { inject } from '@angular/core';

export const corporateEmployeeGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);

  if (localStorageService.getIsCorporateEmployee()) {
    return true;
  } else {
    return false;
  }
};
